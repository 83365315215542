import React from "react"
import Img from 'gatsby-image'
import { StaticQuery, graphql, Link } from "gatsby"

class BlogFeature3 extends React.Component {
  render () {
    return (
      <div className = "otherfeatures" css = {{borderRadius:`20px`,display:`grid`, gridTemplateColumns: `repeat(2, 1fr)`, gridGap: `2rem`,'@media (max-width: 400px)': {margin:`10%`,gridTemplateColumns: `repeat(1, 1fr)`, gridGap: `2rem`}}}>
      <StaticQuery
        query={graphql`
          query TopPosts {
            allMarkdownRemark(sort: { fields: [frontmatter___completion_date], order: DESC }, filter: {frontmatter: {content: {eq: "blog"}}}){
              edges {
                node {
                  excerpt(pruneLength:250)
                  frontmatter {
                    title
                    excerpt
                    path
                    completion_date(formatString: "MMMM D, YYYY")
                    location
                    main_tag
                    img_feature {
                      childImageSharp {
                        fluid(maxWidth:900) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      } }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          data.allMarkdownRemark.edges.map(({ node }) => (
            <div className = "blog_feature" css = {{display:`inline-block`, position:`relative`,width:`100%`, overflow:`hidden`, borderRadius:`20px`}}>
            <Link to = {node.frontmatter.path}>
            <Img className= "blog_image" css = {{height:`100%`, filter:`brightness(40%)`}} fluid = {node.frontmatter.img_feature.childImageSharp.fluid}/>
            <div className = "info" css={{position:`absolute`, bottom:`-50px`, paddingBottom:`50px`, background:`white`, opacity:`0.85`,width:`100%`, paddingLeft:`10px`, paddingRight:`10px`}}>
            <h3 css = {{fontSize:`x-large`, marginBottom:`0.5rem`, marginTop:`0.5rem`, '@media (max-width: 750px)':{fontSize:`large`}}}>{node.frontmatter.title}</h3>
            <p css = {{color: `#7d4aa3`, marginBottom:`0.5rem`, '@media (max-width: 750px)':{fontSize:`medium`}}}> {node.frontmatter.main_tag}</p>
            <p css = {{fontSize: `x-small`, textTransform:`uppercase`, marginBottom:`0.5rem`}}>{node.frontmatter.completion_date}</p>
            </div>
            </Link>
            </div>
                ))
        )}
      />
      </div>

    );
  }
}

export default BlogFeature3
