import React from "react"
import Layout2 from "../components/layout"
import BlogFeature3 from './../elements/blogfeature3.js'
import Button from './../elements/button.js'
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Img from 'gatsby-image'
import Footer from "../components/footer"

export default () =>
<Layout2>
<StaticQuery
  query={graphql`
    query {
      fileName: file(relativePath: { eq: "img/homepage/homepage_preview.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `}
  render={data => (
    <Helmet title="Brett Bejcek: Blog">
  <meta name="description" content="Data Science + Design + Community: I live by my top values of zest, curiosity and creativity. At work, I develop predictive models, encode meaning into visusalizations, and run our experimentation platform. Outside of work, I am an avid journaler, a side project connoisseur, and a coffee chat enthusiast. All thoughts are my own and do not represent clients or employers." />
  <meta name="image" content={data.fileName.childImageSharp.fluid.src} />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Brett Bejcek: Blog" />
  <meta property="og:description" content="Data Science + Design + Community: I live by my top values of zest, curiosity and creativity. At work, I develop predictive models, encode meaning into visusalizations, and run our experimentation platform. Outside of work, I am an avid journaler, a side project connoisseur, and a coffee chat enthusiast. All thoughts are my own and do not represent clients or employers." />
  <meta property="og:image" content={data.fileName.childImageSharp.fluid.src} />
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:title" content="Brett Bejcek: Blog" />
  <meta name="twitter:description" content="Data Science + Design + Community: I live by my top values of zest, curiosity and creativity. At work, I develop predictive models, encode meaning into visusalizations, and run our experimentation platform. Outside of work, I am an avid journaler, a side project connoisseur, and a coffee chat enthusiast. All thoughts are my own and do not represent clients or employers." />
  <meta name="twitter:image" content={data.fileName.childImageSharp.fluid.src} />
    </Helmet>
  )}
/>
<div css = {{marginTop:`50px`,'@media (max-width: 750px)':{marginLeft:`5%`, marginRight:`5%`}}}>
<div css = {{minHeight:`10rem`, borderRadius:`20px`, background:`#7d4aa3`, marginBottom:`2rem`, padding:`2rem`, color:`white`, '@media (max-width: 400px)':{marginLeft:`10%`, marginRight:`10%`}}}><h1 >Writings</h1><p css={{color:`white`}}>A smattering of ideas on data science and intentional living. If you have any feedback, please <a href="mailto:brett@brettbejcek.com"><u> reach out!</u></a></p></div>
<BlogFeature3/>
<br/>
<Button link = "/" cta ='back to home' css={{verticalAlign:`middle`}}></Button>
<br/>
<br/>
<Footer/>
</div>
</Layout2>
